// Include the foundation components
@import "../../../foundation";

.l-bento-f-1 {

  .l-bento-f-1-row-1 {
    @include grid-row();
    max-width: none;

    .l-center {
      @include grid-column(12);
      padding: 0;
    }
  }
}